.App {
    min-height: 90vh; /* Utilisez un pourcentage de la hauteur de la fenêtre pour le conteneur */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre verticalement */
}

.card-content {
  text-align: center; /* Aligne le texte à gauche */
  padding: 20px; /* Ajoute du remplissage autour du texte */
  width: 100%; /* Prend toute la largeur disponible */
  max-width: 600px; /* Limite la largeur pour un meilleur affichage */
  margin: 0 auto; /* Centre la carte horizontalement */
  overflow-wrap: break-word; /* Permet de couper les mots longs */
}

.minted-text {
  line-height: 1.5; /* Améliore l'espacement entre les lignes */
  color: #333; /* Couleur du texte */
}

.minted-nft {
  width: 200px;
  height: auto;
  margin-top: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-personal-app {
  font-family: "Grandstander", cursive !important;
  color: blueviolet;
}